/* ====================================================
    Responsive
==================================================== */
/* Desktop 1200 - up */
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

/* Normal desktop :992px. (Laptop 14") */
@media (min-width: 992px) and (max-width: 1199px) {
    /* Global */
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }

}

 
/* Tablet device :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    /* Global */
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 15px;
    }
    p {
        font-size: 14px;
    }

}

 
/* small mobile :320px. */
@media (max-width: 767px) {
    /* Global */
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 17px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }

}

