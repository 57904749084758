@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&family=Poppins:wght@400;500;600;700;800;900&family=Roboto+Slab&amp;display=swap");


body,html{font-family:'Nunito Sans',sans-serif}
.container,.container-fluid{padding-right:15px;padding-left:15px}
.row{margin-left:-15px;margin-right:-15px}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto{padding-right:15px;padding-left:15px;position:relative}
html{-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}
*{-webkit-box-sizing:border-box;box-sizing:border-box;outline:0;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased}
body{color:#000000;font-weight:400;font-style:normal;font-size:16px;line-height:1.8;margin:0 auto}
.h1,h1{font-size:36px}
.h2,h2{font-size:30px}
.h3,h3{font-size:24px}
.h4,h4{font-size:20px}
.h5,h5{font-size:18px}
.h6,h6{font-size:16px}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{color:#071c1f;clear:both;font-family:Poppins,sans-serif;font-weight:700;line-height:1.3;margin-bottom:15px}
a{color:#5c727d}
p{color: #000000;}
p{margin-bottom:1.5em;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto}
a{-webkit-transition:.3s;-o-transition:.3s;transition:.3s}
a,a:active,a:focus,a:hover{text-decoration:none;outline:0;color:inherit}
a:hover{color:#ff5a3c}
button,code,input,kbd,optgroup,pre,samp,select,textarea,tt,var{font-family:'Nunito Sans',sans-serif}
pre{word-break:break-word}
a i{padding:0 2px}
img{max-width:100%}
ol li,ul li{margin-top:1rem}

.top-bar{ background-color: #000; font-size: 0.95em;}
.header-top-btn, .header-top-btn-mobile { display: inline-block; margin-right:5px; white-space: nowrap }
.header-top-btn a { display: inline-block; padding: 0 20px; line-height: 40px; background-color: #FF5A3C; color: #FFF; }
.header-top-btn-mobile a { display: inline-block; padding: 0 5px; line-height: 30px; background-color: #FF5A3C; color: #FFF; border-radius: 3px; }
.header-top-btn a:hover { background-color: #FF5A3C; }
.header-middle-area { padding-top: 22px; padding-bottom: 22px; background-color:#FFFFFF; align-items: center;}
.header-sticky { z-index: 999; position: fixed; width:100%;  top: 0; -webkit-box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1); box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1); animation: 300ms ease-in-out 0s normal none 1 running fadeInDown; }
.top-bar-search-form{ display: block; transition: .2s; margin-right: 5px;}
.top-bar-search-form-container{ border-radius: 0; min-height: 36px; display: flex; transition: .2s; border: 0 solid transparent;}
.top-bar-search-input {font-family: "Nunito Sans", Sans-serif; font-size: 14px; font-weight: 400; flex-basis: 100%; color: #3f444b; transition: color .2s; border: 0; display: inline-block; padding: 0 10px; line-height: 40px; border-radius: 0px; width:200px;}
.top-bar-search-form-container .react-autosuggest__container{position:relative;}
.top-bar-search-form-container .react-autosuggest__input{font-family: "Nunito Sans", Sans-serif; font-size: 14px; font-weight: 400; flex-basis: 100%; color: #3f444b; transition: color .2s; border: 0; display: inline-block; padding: 0 10px; line-height: 40px; border-radius: 0px; width:200px;}
.top-bar-search-form-container .react-autosuggest__input--focused{outline:0}
.top-bar-search-form-container .react-autosuggest__input--open{border-bottom-left-radius:0;border-bottom-right-radius:0}
.top-bar-search-form-container .react-autosuggest__suggestions-container{display:none}
.top-bar-search-form-container .react-autosuggest__suggestions-container--open{display:block;position:absolute;top:40px;width:100%;border:1px solid #aaa;background-color:#fff;font-weight:300;border-bottom-left-radius:4px;border-bottom-right-radius:4px;z-index:99999}
.top-bar-search-form-container .react-autosuggest__suggestions-list{margin:0;padding:0;list-style-type:none}
.top-bar-search-form-container .react-autosuggest__suggestion{cursor:pointer; padding:5px 10px; margin:0px; font-size:12px; font-weight: 400;}
.top-bar-search-form-container .react-autosuggest__suggestion--highlighted{background-color:#EEEEEE; font-weight: bold;}
.top-bar-search-button { border: 0; display: inline-block; line-height: 40px; background-color: #FF5A3C; color: #FFF; padding: 0 10px}
.before-bg-bottom {  position: relative; }
.before-bg-bottom:before { position: absolute; content: ""; width: 100%; height: 50%; left: 0; bottom: 0; background-color: #171B2A; }
.call-to-action-inner, .call-to-action-inner h1, call-to-action-inner p{ color: #FFF; }
.call-to-action-inner-6 { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 50px 60px; background-color: #FF5A3C; }
.call-to-action-inner-6 .coll-to-info p { margin-bottom: 0; color: #FFF; }
.call-to-action-inner-6 .coll-to-info p + p { margin-top: 15px; }
.call-to-action-inner-6 .btn-wrapper { margin-top: 0; }
.footer-top-area { padding-top: 92px; padding-bottom: 20px; background-color: #171B2A}
.footer-top-area a{ color: #FFF; }
.footer-top-area a:hover { color: #FF5A3C; }
.footer-address ul {  margin: 0;  padding: 0; }
.footer-address ul li { list-style: none; display: block; margin-top: 8px; }
.footer-address ul li:after { display: block; clear: both; content: ""; }
.footer-address ul li .footer-address-icon { float: left; margin-right: 15px; color: #FFF; }
.footer-address ul li .footer-address-info { overflow: hidden; }
.footer-address ul li .footer-address-info p { margin-bottom: 0; }
.footer-social-media ul { margin: 0; padding: 0; }
.footer-social-media ul li { list-style: none; display: inline-block; margin: 0 15px 0 0; }
.footer-social-media ul li:last-child { margin: 0; }
.footer-title { margin-bottom: 25px; color: #FFF !important; }
.footer-menu ul {  margin: 0;  padding: 0; }
.footer-menu ul li {  list-style: none;  margin-top: 0px; margin-bottom: 15px; position: relative; }
.footer-bottom-area { background-color: #282b38; color: #FFF; padding-left:5%; padding-right: 5%; padding-top: 25px; padding-bottom: 25px; }
.copyright-menu ul {  margin: 0;  padding: 0; }
.copyright-menu ul li { list-style: none; display: inline-block;  margin-top: 0; margin-right: 20px; font-family: 'Poppins', sans-serif; font-weight: 600; }
.copyright-menu ul li:last-child { margin-right: 0; }
.copyright-menu ul li a { margin: 0; }
.btn-wrapper { display: block; margin-top: 30px; }
.btn {  border-radius: 0;  display: inline-block;  font-size: 16px;  font-weight: 400;  font-family: 'Poppins', sans-serif;  padding: 17px 40px;  text-align: center;  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;  -o-transition: all 0.3s ease 0s;  transition: all 0.3s ease 0s;  position: relative;  z-index: 1;
  margin-right: 15px; }
.btn.btn-small{padding: 3px 10px; font-size: 13px; border-radius: 3px;}
.btn:last-child{margin-right:0}
.btn-black:hover,.btn-transparent:hover,.btn-white:hover,.theme-btn-1,.theme-btn-2:hover{background-color:#FF5A3C;color:#FFF !important;}
.theme-btn-1:hover,.theme-btn-2{background-color:#000000;color:#FFF}
.theme-btn-3{background-color:transparent;border:2px solid #e4ecf2}
.reverse-color,.theme-btn-3:hover{background-color:#FF5A3C}
.theme-btn-3:hover{border-color:#FF5A3C;color:#FFF}
.reverse-color:hover{background-color:#000000}
.btn-effect-1:after,.btn-effect-2:after{background:#FFF;width:0%;height:100%;top:0}
.btn-white{background-color:#FFF;color:#000000;-webkit-box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28);box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28)}
.btn-black{background-color:#000000;color:#FFF}
.btn-transparent{background-color:transparent;-webkit-box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28);box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28);border-color:#ddd}
.btn-round{border-radius:50px}
.btn-opacity-6{opacity:.6}
.btn-opacity-6:hover,.btn-opacity-7:hover,.btn-opacity-8:hover,.btn-opacity-9:hover{opacity:1}
.btn-opacity-7{opacity:.7}
.btn-opacity-8{opacity:.8}
.btn-opacity-9{opacity:.9}
.btn:after{content:'';position:absolute;z-index:-1;-webkit-transition:.3s;-moz-transition:.3s;-o-transition:.3s;transition:.3s}
.btn-effect-1:after{left:0}
.btn-effect-1:hover,.btn-effect-2:hover{color:#071c1f !important}
.btn-effect-1:hover::after,.btn-effect-2:hover::after,.btn-effect-3:hover::after,.btn-effect-4:hover::after{width:100%}
.btn-effect-2:after{right:0}
.btn-effect-3:after{width:0%;height:100%;top:0;left:0;background:#000000}
.btn-effect-3:hover,.btn-effect-4:hover{color:#FFF}
.btn-effect-4:after{width:0%;height:100%;top:0;left:0;background:#FF5A3C}
.btn-full-width{display:block}
.btn-full-width-2{display:block;width:100%}
.btn-border{border:2px solid #ebeeee;-webkit-box-shadow:none;box-shadow:none}
.entry-title {color: #071C1F; font-family: "Poppins", sans-serif; font-size: 24px; font-weight: 700; text-transform: uppercase; text-align: center; margin: 0px 0px 20px 0px; padding: 20px; background-color: #EEEEEE;}
#scrollUp{background-color:#F2F6F7;color:#071c1f;bottom:70px;font-size:20px;font-weight:700;height:40px;width:40px;right:3%;text-align:center;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);-webkit-transition:.3s;-o-transition:.3s;transition:.3s;-webkit-box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28);box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28); border:0px;}
#scrollUp i{line-height:40px;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg)}
#scrollUp:hover{background-color:#FF5A3C;color:#FFFFFF;}
#page-content a { color: #068282 }
#page-content a:hover { color: #ff5a3c; }
table.sortable-table{ background-color: #F4F4F4; font-size: 14px; }
table.sortable-table td, table.sortable-table th{ border: 1px solid #969696; background-color: #FFF; padding: 5px; color: #000000}
table.sortable-table th{ border: 1px solid #646464; background-color: #999999 !important; cursor: pointer !important; padding: 5px 20px 5px 5px; color: #FFF; position: relative;}
.sortable-table-collapsible-btn {padding:0px; margin:0px; border:0px; color:#068282; background: none; font-weight: bold;}
.sortable-table-collapsible-btn:hover {color: #ff5a3c;}
.sortable-table-stickyhead{ position: sticky; top: 6.7em;}
table.sortable-table th .sort-icon{ position: absolute; right: 3px; top: 50%; transform: translateY(-50%);}
.blackhead{ background-color: #29292A; font-weight: 600; color: #FFF; font-size: 1em; border-bottom: 4px solid #ff5a3c; padding-top: 10px; padding-bottom: 10px; margin-bottom: 10px;}
.orangehead{ background-color: #ff5a3c; font-weight: 600; color: #FFF; font-size: 1em; border-bottom: 4px solid #29292A; padding-top: 10px; padding-bottom: 10px; margin-bottom: 10px;}
table.deal-stats-table{ width:100% !important; background-color: #f5f5f4; }
table.deal-stats-table td, table.deal-stats-table th { border: 3px solid #FFF; background-color: #f5f5f4; width: 25%; padding: 10px;}
.condapply { color: #F00; }
.loadmore-btn {text-align: center; background-color: #f5f5f4; width:100%; padding: 10px; margin-bottom: 10px; font-weight: bold; text-transform: uppercase; border: 1px solid #e0e0e0; color: #068282;}
.stock-news-info {color: #999; font-size: 12px;}
.stock-price{font-size: 2em; font-weight:700;}
.stock-price-change{font-size: 1.40em; font-weight:700;}
.stock-price-change.positive-change{color:#0a7b44;}
.stock-price-change.negative-change{color:#c9392c;}
.deal-metrics-link-section{ width:65%; text-align:center; margin-right:10px; padding:5px; border: 2px solid #F8674A; border-radius:10px; font-size:0.75em; font-weight:bold; }
.stock-follow-button{padding:5px 10px;font-weight:700;color:#fff;background-color:#f8674a;border-radius:20px;font-size:14px;cursor:pointer}
.stock-follow-button.small{padding:2px;font-size:15px;background:0 0;color:#f8674a}
.stock-follow-button.following{background-color:#068282}
.stock-follow-button.small.following{background:0 0;color:#068282}
.stock-follow-button.following:hover,.stock-follow-button:hover{background-color:#000}
.stock-follow-button.small.following:hover,.stock-follow-button.small:hover{background:0 0;color:#000}
.stock-follow-popup{background-color:#fff;padding:10px;min-width:300px;max-width:300px;max-height:400px;z-index:2;position:absolute;top:35px;box-shadow:0 2px 4px rgba(0,0,0,.2);border-radius:8px;border:1px solid #e0e4e9;overflow:hidden;overflow-y:auto}
table.portfolio-data-table { box-sizing: border-box;  border-collapse: separate; border-spacing: 0; border: 0px !important; font-size:0.90em;}
table.portfolio-data-table th, table.portfolio-data-table td{ padding: 5px; border-bottom: 1px solid #DBDBDB !important; box-sizing: content-box; border-top: none !important; border-left: none !important; border-right: none !important; font-size: 0.95em !important;}
table.portfolio-data-table thead th, table.portfolio-data-table thead.stickyhead th{ font-weight: bold; border-bottom: 2px solid #000 !important; text-align: left; font-size: 0.80em !important; background-color:#FFF; cursor: pointer; position:relative; padding-right:15px;}
table.portfolio-data-table thead.stickyhead { position:sticky; top: 6.2em; z-index:2 }
table.portfolio-data-table thead th .sort-icon{ position: absolute;	right: 3px;	top: 50%; transform: translateY(-50%); }
table.portfolio-data-table tbody tr.odd{background-color: #F3F4F7;}
table.portfolio-data-table tbody tr.even{background-color: #FFF;}
table.portfolio-data-table td a { color: #068282; }
table.portfolio-data-table td a:hover { color: #FF5A3C !important; }
table.portfolio-data-table tfoot td{ padding: 5px; border-bottom: none !important; box-sizing: content-box; border-top: 2px solid #000 !important; border-left: none !important; border-right: none !important; font-size: 0.95em !important;}
.portfolio-data-table-loader{ position: absolute; width: 100%; height: 100%; background: rgba(255,255,255,0.5); top: 0; left: 0; z-index:2;}
.portfolio-data-table-loader-spinner{ margin-top:80px;}
.homepage-search-area{ background-color:#EEEEEE; padding: 50px 0px 50px 0px; margin-top: 20px; margin-bottom: 25px;}
.homepage-search-area .react-autosuggest__container{position:relative; width: 80%;}
.homepage-search-area .react-autosuggest__input{width:100%;height:51px;padding:10px 20px;font-weight:300;border:1px solid #aaa;border-radius:4px;}
.homepage-search-area .react-autosuggest__input--focused{outline:0}
.homepage-search-area .react-autosuggest__input--open{border-bottom-left-radius:0;border-bottom-right-radius:0}
.homepage-search-area .react-autosuggest__suggestions-container, .siteupdates-search-area .react-autosuggest__suggestions-container{display:none}
.homepage-search-area .react-autosuggest__suggestions-container--open, .siteupdates-search-area .react-autosuggest__suggestions-container--open{display:block;position:absolute;top:51px;width:100%;border:1px solid #aaa;background-color:#fff;font-weight:300;border-bottom-left-radius:4px;border-bottom-right-radius:4px;z-index:3}
.siteupdates-search-area .react-autosuggest__suggestions-container--open{top:inherit !important; width: calc(100% - 30px) !important;}
.homepage-search-area .react-autosuggest__suggestions-list, .siteupdates-search-area .react-autosuggest__suggestions-list {margin:0;padding:0;list-style-type:none}
.homepage-search-area .react-autosuggest__suggestion, .siteupdates-search-area .react-autosuggest__suggestion {cursor:pointer;padding:5px 10px; margin:0px;}
.homepage-search-area .react-autosuggest__suggestion--highlighted, .siteupdates-search-area .react-autosuggest__suggestion--highlighted {background-color:#EEEEEE; font-weight: bold;}
.common_title { color: #071c1f !important; font-family: "Poppins",Sans-serif !important; font-size: 24px !important; font-weight: 700 !important; text-transform: uppercase !important;}
.bg-white {background-color: #FFF !important; color: #000 !important;}
.ltn__border { border: 1px solid #e5eaee;}
.box-shadow-1 {-webkit-box-shadow: 0 16px 32px 0 rgba(7,28,31,.1); box-shadow: 0 16px 32px 0 rgba(7,28,31,.1); }
.home-page-news blockquote{ font-weight: normal; font-style: italic; color: #0B1FB2; font-size: 0.80em; margin-top: 10px; margin-bottom: 5px;}
.home-page-news a { color: #068282; }
.home-page-news a:hover { color: #FF5A3C !important; }
.login-page-title { background-color: #000; color: #FFF; border-top-left-radius: 10px; border-top-right-radius: 10px; padding-top: 10px; padding-bottom: 10px;}
.login-page-title h4 { color:#FFF; font-family: "Poppins",Sans-serif !important; padding: 0px; margin: 0px; font-weight: normal;}
.delete-btn-portfolio{ border: none; padding:0; margin:0; background:none; color: #068282; }
.have-no-notes-btn-portfolio, .have-no-web-btn-portfolio { border: none; padding:0; margin:0; background:none; color: #00F; }
.have-notes-btn-portfolio, .have-web-btn-portfolio{ border: none; padding:0; margin:0; background:none; color: #0A7B44; }
.delete-btn-portfolio:hover,.have-no-notes-btn-portfolio:hover,.have-notes-btn-portfolio:hover, .have-no-web-btn-portfolio:hover, .have-web-btn-portfolio:hover { color: #FF5A3C; } 
.product-action li:hover a,.product-badge li{ background-color:#FF5A3C }
.ltn__product-item-3 .product-img::before,.ltn__product-item-4:hover .product-img::before{ display:none }
.ltn__product-item{ position:relative; height: 100%; }
.product-ratting,.product-title{ margin-bottom:5px }
.product-img{ position:relative;overflow:hidden }
.product-img img{ position:relative;-webkit-transition:3.5s;-o-transition:3.5s;transition:3.5s }
.product-hover-action,.product-img:before{ opacity:0;visibility:hidden;-webkit-transition:.3s;-o-transition:.3s;position:absolute;left:0 }
.product-img:before{ content:"";top:0;width:100%;height:100%;background-color:#000000;transition:.3s }
.ltn__product-item:hover .product-img::before,.ltn__product-item:hover .product-img:before{ opacity:.4;visibility:visible }
.product-title{ font-size:18px }
.product-badge ul,.product-ratting ul{ margin:0;padding:0 }
.product-ratting li{ display:inline-block;margin:0 -2px;color:#FFB800;font-size:12px }
.ltn__product-item-4 .product-img-location-gallery .product-img-gallery ul li:last-child,.ltn__product-item-5 .ltn__plot-brief li:last-child,.product-hover-action li{ margin-right:0 }
.product-hover-action{ top:60%;right:0;text-align:center;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);transition:.3s }
.button-1 a,.product-action li a{ -webkit-transition:.3s;-o-transition:.3s }
.ltn__product-item:hover .product-hover-action{ top:50%;opacity:1;visibility:visible }
.product-hover-action ul{ margin:0;padding:0;display:inline-block;-webkit-box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28);;box-shadow:0 1px 6px 0 rgba(32, 33, 36, .28); }
.product-hover-action li{ height:50px;width:50px;line-height:50px;font-size:14px;text-align:center;float:left;margin-top:0;list-style:none;border-right:1px solid #d1dae0 }
.product-hover-action li:last-child{ margin-right:0;border-right:0 }
.product-hover-action li a{ display:block;background-color:#fff;font-weight:700 }
.button-1:hover a,.product-hover-action li:hover a{ background-color:#FF5A3C;color:#fff }
.product-badge{ position:absolute;top:15px;right:18px }
.product-badge li{ list-style:none;display:inline-block;font-size:13px;font-weight:600;color:#fff;padding:3px 15px 0;text-transform:uppercase;line-height:25px }
.ltn__product-item-5,.ltn__product-item-5 .product-img,.ltn__product-item-5 .product-info,.product-badge li:first-child{ margin-top:0 }
.product-badge li::before{ position:absolute;content:"";bottom:-8px;left:0;border-left:8px solid #FF5A3C;width:0;height:0;border-bottom:8px solid transparent;display:none }
.product-badge li .soldout-badge{ background-color:#f34f3f }
.product-info{ padding:20px 0 0 }
.product-price{ color:#071c1f;font-weight:600 }
.product-price del{ opacity:.6;margin-left:5px;font-size:80% }
.ltn__product-item .product-price{ margin-bottom:12px }
.product-action ul{ margin:10px 0 0;padding:0 }
.product-action li{ display:inline-block;margin-top:0 }
.product-action li a{ display:inline-block;padding:3px 15px;border:1px solid #071c1f;color:#071c1f;transition:.3s }
.product-action li:hover a{ color:#fff;border-color:#FF5A3C }
.button-1 a{ display:inline-block;padding:5px 15px;background-color:#071c1f;color:#fff;transition:.3s }
.ltn__product-item-2 .product-img img{ background-color:#F2F6F7 }
.ltn__product-item-2:hover .product-img img{ -webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1) }
.ltn__product-item .add-to-cart{ width:auto;text-transform:uppercase }
.ltn__product-item .add-to-cart a{ padding:0 15px }
.ltn__product-item .add-to-cart span{ font-size:12px;line-height:1 }
.ltn__product-item-3{ border:2px solid;border-color:#f6f6f6;-webkit-transition:.3s;-o-transition:.3s;transition:.3s }
.ltn__product-item-3 .product-info{ padding:25px 30px 15px }

.ltn__product-item-3 .product-title{ font-size:16px;margin-bottom:0 }
.ltn__product-item-3 .product-price{ font-size:20px;color:#FF5A3C;font-weight:700 }
.ltn__product-item-3 .product-price del{ font-size:20px;opacity:.6 }
.ltn__product-item-3 .product-info-brief{ border-top:2px solid;border-color:#f6f6f6;margin-bottom:15px;max-width:400px }
.ltn__product-item-3 .product-info-brief ul{ margin:0;padding:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between }
.ltn__product-item-4 .product-img a,.ltn__product-item-4 .product-img-location-gallery .product-img-gallery ul li a,.ltn__product-item-5 .product-img a{ display:block }
.ltn__product-item-3 .product-info-brief ul li{ list-style:none;display:inline-block;font-size:14px;font-weight:700 }
.ltn__product-item-3 .product-info-brief ul li i{ color:#FF5A3C;margin-right:5px }
.ltn__product-item-3:hover{ -webkit-box-shadow:0 16px 32px 0 rgba(7, 28, 31, 0.1);box-shadow:0 16px 32px 0 rgba(7, 28, 31, 0.1) }
.ltn__product-item-3:hover .product-hover-action{ top:70% }
.ltn__product-item-4{ -webkit-box-shadow:0 5px 20px 0 rgba(23, 44, 82, 0.1);box-shadow:0 5px 20px 0 rgba(23, 44, 82, 0.1);border:1px solid;border-color:#f1f8ff;background-color:#fff }
.ltn__product-item-4 .product-img{ position:relative }
.ltn__product-item-4 .product-img img{ -webkit-transition:.5s;-o-transition:.5s;transition:.5s }
.ltn__product-item-4 .product-img-location-gallery{ position:absolute;bottom:0;left:0;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 30px 15px;margin:0;background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 70%); }
.ltn__product-item-4 .product-img-location-gallery ul{ padding:0;margin:0;position:relative;z-index:2 }
.ltn__product-item-4 .product-img-location-gallery ul li{ list-style:none;font-size:14px;color:#fff }
.ltn__product-item-4 .product-img-location-gallery .product-img-gallery{ margin-left:10px }
.ltn__product-item-4 .product-img-location-gallery .product-img-gallery ul li{ display:inline-block;width:42px;height:30px;line-height:30px;background-color:rgba(255,255,255,.3);margin-right:5px;text-align:center }
.ltn__product-item-4 .product-info{ padding:30px 30px 1px }
.ltn__product-item-4 .product-price{ font-size:18px;font-family:'Poppins', sans-serif;font-weight:600;color:#FF5A3C;margin-bottom:5px }
.ltn__product-item-4 .product-price label{ font-weight:400;font-size:14px }
.ltn__product-item-4 .product-title{ font-size:22px;margin-bottom:15px;font-weight:600 }
.ltn__product-item-4 .product-description p{ font-size:14px;margin-bottom:15px }
.ltn__product-item-4 .product-info-bottom{ display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-top:1px solid;border-color:#e5eaee;;padding:20px 30px 30px; font-family: "Roboto Slab", Sans-serif; font-weight: 400; font-size: 12px; color: #adadad}
.ltn__product-item-4 .product-hover-action{ position:relative;opacity:1;visibility:visible;-webkit-transform:inherit;-ms-transform:inherit;transform:inherit;line-height:1 }
.ltn__product-item-4 .product-hover-action ul{ -webkit-box-shadow:none;box-shadow:none }
.ltn__product-item-4 .product-hover-action ul li{ margin-right:8px;height:36px;width:36px;line-height:42px;border-right:0;font-size:16px;background-color:#F2F6F7;-webkit-transition:.3s;-o-transition:.3s;transition:.3s }
.ltn__product-item-4 .product-hover-action ul li a{ background-color:transparent }
.ltn__product-item-4 .product-hover-action ul li:hover{ background-color:#FF5A3C;color:#fff }
.ltn__product-item-4:hover .product-img img{ -webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1) }
.real-estate-agent{ display:-webkit-box;display:-ms-flexbox;display:flex }
.real-estate-agent .agent-img{ max-width:40px;margin-right:12px }
.real-estate-agent .agent-img img{ border-radius:100% }
.real-estate-agent .agent-brief h6{ margin-bottom:0;font-size:14px;font-weight:600 }
.real-estate-agent .agent-brief small{ font-size:12px }
.ltn__product-item-5 .product-info-bottom{ padding:20px 30px 15px }
.ltn__product-item-5 .product-badge{ position:inherit;margin-bottom:15px }
.ltn__product-item-5 .product-badge li{ font-size:16px;background-color:transparent;color:#FF5A3C;padding:0 }
.ltn__product-item-5 .product-img-location ul{ padding:0;margin-bottom:0 }
.ltn__product-item-5 .product-img-location ul li{ list-style:none;font-size:14px }
.ltn__product-item-5 .product-img-location ul li i{ color:#FF5A3C;padding-left:0 }
.ltn__product-item-5 .ltn__plot-brief{ padding:0;margin-bottom:20px }
.ltn__product-item-5 .ltn__plot-brief li{ list-style:none;display:inline-block;margin-right:10px;font-size:14px }
.ltn__product-item-5 .ltn__plot-brief li span{ font-weight:700 }
.ltn__product-item-5 .real-estate-agent{ position:absolute;top:20px;right:20px;-webkit-transition:.3s;-o-transition:.3s;transition:.3s }
.ltn__product-item-5 .real-estate-agent .agent-img{ max-width:50px }
.ltn__product-item-5 .real-estate-agent .agent-img img{ border:3px solid;border-color:#e5eaee; }
.ltn__product-item-5 .product-hover-action{ text-align:left;margin-bottom:25px }
.ltn__product-list-view .ltn__product-item-5{ padding:30px }
.ltn__product-list-view .ltn__product-item-5 .real-estate-agent{ position:inherit;margin-top:15px }
.ltn__product-list-view .ltn__product-item-5 .product-info-bottom{ padding:0 0 0 30px;border:0 }
.ltn__product-list-view .ltn__product-item-5 .product-hover-action{ margin-bottom:0 }
.ltn__product-list-view .ltn__product-item-5 .ltn__plot-brief{ margin-bottom:5px }
.ltn__product-list-view .ltn__product-item-5 .product-badge-price{ display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between }

@media (min-width: 768px) and (max-width: 991px) {
	.btn { padding: 12px 25px; }
	[type="submit"].btn { padding: 15px 30px; } 
}

@media only screen and (max-width: 767px) {
	.btn {padding: 10px 20px; font-size: 14px; }
	[type="submit"].btn { padding: 12px 30px; } 
}

@media (max-width: 991px) {
	.call-to-action-inner-6 { padding: 50px 25px; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column; flex-flow: column; }
    .call-to-action-inner-6 .btn { margin-top: 30px; }
	#scrollUp{font-size:16px;height:30px;width:30px;bottom:50px}
	#scrollUp i{line-height:30px}
}
#navigation-menu ul{padding:0;margin:0}
#navigation-menu .navigation-menu-main-menu,#navigation-menu input[type=checkbox],#navigation-menu ul span.drop-icon{display:none}
#navigation-menu-tm:checked+.navigation-menu-main-menu{display:block;position:absolute;width:100%;background-color:#fff;padding:0 15px;right:0;top:110%}
#navigation-menu .navigation-menu-sub-menu{background-color:#fff;margin:0;display:none}
#navigation-menu .navigation-menu-sub-menu li:last-child{border-width:0}
#navigation-menu a,#navigation-menu li,#navigation-toggle-menu{position:relative;display:block;color:#000;font-family:'Nunito Sans',sans-serif;font-size:1em;font-style:normal;font-weight:600;font-variant:normal}
#navigation-menu .navigation-menu-sub-menu a{color:#000!important}
#navigation-toggle-menu{background:#fff;color:#000!important;margin-top:10px;cursor:pointer}
#navigation-menu a{transition:.125s ease-in-out;-webkit-transition:.125s ease-in-out}
#navigation-menu a:hover{color:#ff5a3c}
#navigation-menu .navigation-menu-sub-menu li{margin-right:0!important}
#navigation-menu input[type=checkbox]:checked+.navigation-menu-sub-menu{display:block}
#navigation-menu .navigation-menu-sub-menu a:hover{color:#ff5a3c!important}
#navigation-menu li label.drop-icon{position:absolute;right:0;top:0}
#navigation-toggle-menu span.drop-icon{padding:5px;font-size:20px;text-align:center;background-color:rgba(0,0,0,.125);text-shadow:0 0 0 transparent}
.navbar-container{position:absolute!important;right:0;z-index:2;width:100%}
@media only screen and (min-width:1200px){
	#navigation-menu .navigation-menu-main-menu,#navigation-menu li:hover>input[type=checkbox]+.navigation-menu-sub-menu{display:block}
	#navigation-menu .navigation-menu-sub-menu,#navigation-menu input[type=checkbox]:checked+.navigation-menu-sub-menu,#navigation-menu label.drop-icon,#navigation-toggle-menu{display:none}
	#navigation-menu ul span.drop-icon{display:inline-block}
	#navigation-menu li{float:left;border-width:0;margin-right:25px}
	#navigation-menu .navigation-menu-sub-menu li{float:none;padding:2px 5px;margin-top:0}
	#navigation-menu .navigation-menu-sub-menu{border-width:0;margin:0;position:absolute;top:100%;left:0;width:200px;z-index:3000}
	#navigation-menu .navigation-menu-sub-menu .navigation-menu-sub-menu{top:0;left:100%}
	#navigation-menu a{padding:0!important}
	#navigation-menu-tm:checked+.navigation-menu-main-menu{display:block;position:static;width:auto;background-color:#fff;padding:0 15px;right:0;top:110%}
}
.modal-dialog{margin-top:150px}
.modal-content{border-radius:0}
.modal-header{padding:0;border:0}
.modal-header .close{position:absolute;height:40px;width:40px;line-height:40px;padding:0;right:16px;left:auto;top:16px;opacity:1;z-index:1;-webkit-transition:.3s;-o-transition:.3s;transition:.3s; border: none; font-weight: bold;}
.modal-header .close span{display:block;font-size:25px}
.modal-header .close:hover,.modal-header .close:not(:disabled):not(.disabled):focus,.modal-header .close:not(:disabled):not(.disabled):hover{opacity:1}
@media (min-width:992px){
	.modal-lg{max-width:980px}
}
.modal-product-info{padding-left:25px}
.modal-product-info h3{font-size:24px;margin-bottom:10px}
.modal-product-info hr{margin-top:20px;margin-bottom:20px}
.modal-product-info .product-price{font-size:50px;margin-bottom:10px;color:#FF5A3C;line-height:1.2;font-weight:600}
.modal-product-meta>ul{margin:15px 0;padding:20px 0;border-bottom:1px solid #e1e6ff;border-top:1px solid #e1e6ff}
.modal-product-meta>ul>li{list-style:none;font-size:14px;margin-top:10px}
.modal-product-meta>ul>li:first-child{margin-top:0}
.modal-product-meta li strong{color:#071c1f;margin-right:5px;font-weight:600;min-width:100px;display:inline-block}
.modal-product-quantity input{border:1px solid #e5e5e5;float:left;height:45px;text-align:center;width:80px;margin-right:20px}
.modal-btn{margin-top:15px;border-top:1px solid #f1f1f1;text-align:right}
.modal-btn a{font-size:14px;color:#071c1f;padding:5px 10px}
.ltn__modal-area .modal-btn{border:0}
.ltn__add-to-cart-modal-area .modal-body,.modal-body{padding:30px}
.ltn__add-to-cart-modal-area .modal-product-img{float:left;max-width:125px;margin-right:20px}
.ltn__add-to-cart-modal-area .modal-product-info{overflow:hidden}
.ltn__add-to-cart-modal-area .added-cart i{color:#77C720}
.ltn__add-to-cart-modal-area .btn-wrapper{margin-top:20px}
.ltn__add-to-cart-modal-area .btn-wrapper .btn{padding:5px 20px;margin-right:10px}
.ltn__add-to-cart-modal-area .additional-info{border-top:1px solid #e5eaee;text-align:center;padding-top:30px;margin-top:40px}
.modal-backdrop.show{opacity:.7}
@media (max-width: 767px) {
    .modal-product-info h3 { font-size: 22px; }
    .modal-product-info .product-price {font-size: 34px; }
}
.ltn__newsletter-popup-2 .modal-header .close{background-color:#F2F6F7;color:#071c1f;border-radius:0 0 0 20px}
.ltn__newsletter-popup-2 .modal-body{padding:0}


